<template>
  <table
    style="position: relative; width: 100%"
  >
    <tr
      v-for="(group, index) in groupedQrCodes"
      :key="index"
    >
      <td
        v-for="(qr, index2) in group"
        :key="index2"
        style="text-align: center; padding-bottom: 25px;"
      >
        <div class="name">
          {{ qr.firstName }} {{ qr.lastName }}
        </div>
        <qr-code :text="qr.qrToken"></qr-code>
      </td>
    </tr>
  </table>
</template>
<script>
import axios from '@axios'

export default {
  data: () => ({
    qrCodes: [],
  }),
  computed: {
    groupedQrCodes() {
      const groups = []

      this.qrCodes.forEach((item, index) => {
        const groupIndex = Math.floor(index / 3)

        if (!groups[groupIndex]) {
          groups[groupIndex] = []
        }

        groups[groupIndex].push(item)
      })

      return groups
    },
  },

  mounted() {
    document.title = 'QR-codes afdrukken - BlokPass'
    const school = JSON.parse(localStorage.getItem('school'))
    this.qrIds = JSON.parse(localStorage.getItem('qrIds')) || []
    axios.put(`/school/${school.id}/qr`, {
      accountIds: this.qrIds,
    })
      .then(res => {
        console.log(res)
        console.log(res.data)
        this.qrCodes = res.data.accounts
        setTimeout(() => {
          window.print()
        }, 2000)
      })

    setTimeout(() => {
      // window.print()
    }, 1000)
  },
}
</script>

<style>
.theme--light.v-application {
  background: white;
}
img {
  max-width: 100%;
  height: auto;
  width: 80%;
  margin: auto;
}

.name {
  font-size: large;
  color: black;
  margin-bottom: 10px;
}

tr {
  page-break-inside: avoid;
}

td>div {
  width: 32vw;
  margin:auto;
}
</style>
